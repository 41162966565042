<template lang="pug">
  v-container
    v-snackbar(v-model="flags.snackbar" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="flags.snackbar = false" ) Fechar
    v-dialog(
      v-model="flags.create"
      :max-width="$vuetify.breakpoint.smAndDown ? undefined : '800'"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      v-card(:loading="loadings.create.send")
        v-toolbar.elevation-0
          v-card-title Criar grupo
          v-spacer
          v-btn(
            color="grey"
            icon
            large
            dense
            @click="flags.create = false"
          )
            v-icon close
        v-card-text
          v-row
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 6")
              v-text-field(
                v-model="create.form.name"
                label="Nome do grupo"
                filled
                rounded
                :disabled="loadings.create.send"
              )
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 6")
              v-text-field(
                v-model="create.form.cod"
                label="Código do grupo"
                filled
                rounded
                :disabled="loadings.create.send"
              )
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 6")
              v-select(
                v-model="create.form.type"
                :items="groups.types"
                item-text="name"
                item-value="slug"
                label="Tipo do grupo"
                filled
                rounded
                :disabled="loadings.create.send"
              )
        v-card-actions
          v-spacer
          v-btn(
            depressed
            color="primary"
            @click="createGroup"
            :loading="loadings.create.send"
          )
            span Salvar

    //- Gerenciar membros
    v-dialog(
      v-model="flags.members"
      :max-width="$vuetify.breakpoint.smAndDown ? undefined : '800'"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      v-card(:loading="loadings.members.send || loadings.members.add.search")
        v-toolbar.elevation-0
          v-card-title Gerenciar membros do grupo
          v-spacer
          v-btn(
            color="grey"
            icon
            large
            dense
            @click="flags.members = false"
          )
            v-icon close
        v-card-text
          v-row
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
              v-autocomplete(
                v-model="members.add.selectedUsers"
                :items="members.add.search"
                :search-input.sync="members.add.searchText"
                item-text="name"
                return-object
                label="Novos membros"
                persistent-hint
                filled
                rounded
                multiple
                hide-no-data
                hide-details
                :disabled="loadings.members.send"
                :error-messages="errors.members.selection"
              )
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
              v-select(
                v-model="members.add.selectedRole"
                item-text="name"
                return-object
                :items="members.roles"
                label="Papel no grupo"
                filled
                rounded
                :disabled="loadings.members.send"
                :error-messages="errors.members.selection"
                hide-details
              )
            v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 2")
              v-btn.ma-2.primary(
                fab
                icon
                small
                @click="addSelectedMembers()"
                :disabled="loadings.members.send"
                title="Adicionar usuários"
              )
                v-icon(color="white") add
          v-row
            v-col(cols=12)
              span.subtitle-2 Membros
            List(
              v-if="selectedGroup"
              action="groups/peopleList"
              offset=15
              :params="{ group_cod: selectedGroup.cod}"
              search-label="Pesquisar membro"
              no-item-message="O grupo não possui nenhum membro"
              item-icon="person"
              item-value="id"
              primary-item-text="name"
              secondary-item-text="role"
              max-height="300px"
              @removed="removeMember"
            )
          v-row(v-if="members.add.form.selected.length > 0")
            v-col(cols=12).pb-1
              span.subtitle-2 Novos membros
            v-col(cols=12)
              perfect-scrollbar
                v-list(max-height="300px")
                  v-list-item(v-for="(user, index) of members.add.form.selected" :key="`newMember-${index}`")
                    v-list-item-avatar
                      v-icon person
                    v-list-item-content
                      v-row
                        v-col(cols=6) {{user.name}}
                        v-col(cols=6) {{user.roleName}}
                    v-list-item-action-text
                      v-btn(
                        icon
                        @click="removeAddedMember(user)"
                      )
                        v-icon remove_circle
        v-card-actions
          v-spacer
          v-btn(
            depressed
            color="primary"
            :loading="loadings.members.send"
            @click="saveMembers"
          )
            span Salvar

    v-col()
      v-breadcrumbs(:items='crumbs' divider='>')
        template(v-slot:item='props')
          v-slide-y-transition
            v-breadcrumbs-item(@click='changePath(props.item)')
              v-card.pa-1.my-1(:hover='!props.item.disabled' :flat='props.item.disabled' :outlined='!props.item.disabled' :color="props.item.disabled ? 'transparent' : '' ")
                span.body-2 {{ props.item.text }}
    v-col()
      v-card(outlined)
        v-list
          v-slide-y-transition(hide-on-leave)
            v-list-item(v-if='currentGroups.length === 0')
              v-list-item-content
                v-col
                  v-row(no-gutters)
                    v-progress-linear(v-if='loadings.groups' height="6"  indeterminate rounded)
                    span.subtitle-1(v-else) Grupo sem subgrupos
          v-slide-y-transition(group hide-on-leave)
            template(v-if='currentGroups.length > 0' v-for='(group, i) in currentGroups')
              v-list-item(two-line @click.stop='navigate(group)' :key="group.id")
                v-list-item-content
                  v-col
                    v-row(no-gutters)
                      span.subtitle-1 {{ group.name }}
                    v-row(no-gutters)
                      span.body-2.font-weight-light {{ group.cod }}
                v-list-item-action
                  v-row(no-gutters)
                    //- v-col()
                    //-   v-tooltip(bottom)
                    //-     template(v-slot:activator='{ on }')
                    //-       v-btn(v-on='on' icon :small='isMobile' @click.stop='addSubgroup(group)')
                    //-         v-icon(color='primary') add
                    //-     span Adicionar subgrupo
                    v-col()
                      v-tooltip(bottom)
                        template(v-slot:activator='{ on }')
                          v-btn(v-on='on' icon :small='isMobile' @click.stop='openMembersDialog(group)')
                            v-icon(color='primary') person
                        span Gerenciar membros do grupo
                    v-col()
                      v-tooltip(bottom)
                        template(v-slot:activator='{ on }')
                          v-btn(v-on='on' icon :small='isMobile' @click.stop='renameGroup(group)')
                            v-icon(color='primary') edit
                        span Renomear grupo
                    v-col()
                      v-tooltip(bottom)
                        template(v-slot:activator='{ on }')
                          v-btn(v-on='on' icon :small='isMobile' @click.stop='deleteGroup(group)')
                            v-icon(color='error') delete
                        span Remover grupo
              v-divider(v-if='i < currentGroups.length - 1' :key="i")
    v-btn(
      color="primary"
      fab
      bottom
      right
      fixed
      @click="openCreateGroupDialog"
      :loading="loadings.groups"
    )
      v-icon add
</template>

<script>
import { mapState } from 'vuex'
import organizationGroupsApi from '../api/organizationgroups'
import List from '../components/List'

export default {
  name: 'groups',
  components: {
    List
  },
  data () {
    return {
      flags: {
        members: false,
        snackbar: false,
        create: false
      },
      snackbar: {
        text: null
      },
      errors: {
        members: {
          selection: ''
        }
      },
      loadings: {
        members: {
          send: false,
          members: false,
          add: {
            search: false
          }
        },
        create: {
          send: false
        },
        groups: false
      },
      create: {
        form: {
          name: null,
          cod: null,
          type: null
        }
      },
      members: {
        add: {
          form: {
            selected: []
          },
          selectedUsers: [],
          selectedRole: null,
          search: [],
          searchText: null,
          nextSearchText: null
        },
        form: {
          removed: []
        },
        roles: [{
          name: 'Editor',
          value: 'publisher'
        }, {
          name: 'Visualizador',
          value: 'viewer'
        }, {
          name: 'Moderador',
          value: 'moderator'
        }]

      },
      selectedGroup: null,
      path: this.path || 'g',
      currentGroups: [],
      crumbs: [
        {
          text: 'Grupos',
          group: {
            cod: 'g'
          },
          path: 'g',
          disabled: true
        }
      ]
    }
  },
  url: {
    path: 'path'
  },
  computed: {
    ...mapState(['groups']),
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  async created () {
    this.loadings.groups = true
    await this.$store.dispatch('groups/list')
    await this.$store.dispatch('groups/listTypes')
    this.loadings.groups = false
    if (this.path === 'g') {
      this.getTopLevelGroups()
    } else {
      this.processPath()
    }
  },
  watch: {
    path: function (newPath, oldPath) {
      if (newPath === 'g') {
        this.crumbs = [{
          text: 'Grupos',
          group: {
            cod: 'g'
          },
          path: 'g',
          disabled: true
        }]
        this.filterGroups(g => g.type === 'filial')
      } else {
        this.processPath(newPath)
      }
    },

    'members.add.searchText': function (text) {
      this.errors.members.selection = ''
      this.searchUsers(text)
    },
    'members.add.selectedRole': function () {
      this.errors.members.selection = ''
    },
    'flags.members': function (value) {
      if (!value) {
        this.cleanMembersDialogState()
      }
    },
    'flags.create': function (value) {
      if (!value) {
        this.cleanCreateDialogState()
      }
    }
  },
  methods: {
    cleanCreateDialogState () {
      Object.assign(this.create.form, {
        name: null,
        cod: null,
        type: null
      })

      Object.assign(this.loadings.create, {
        send: false
      })
    },
    async createGroup () {
      const pathVector = this.path.split('/')
      const leaf = pathVector[pathVector.length - 1]

      try {
        this.loadings.create.send = true
        const result = await organizationGroupsApi.create({
          ...this.create.form,
          parent_cod: leaf !== 'g' ? leaf : null
        })
        this.currentGroups.push(result.group)
        this.loadings.create.send = false

        this.snackbar.text = 'Grupo criado com sucesso'
        this.flags.snackbar = true

        this.flags.create = false
      } catch (err) {
        console.log(err)

        this.snackbar.text = 'Erro ao criar o grupo'
        this.flags.snackbar = true
      }
    },

    openCreateGroupDialog () {
      this.flags.create = true
    },
    removeMember (user) {
      this.members.form.removed.push(user)
    },
    async saveMembers () {
      try {
        this.loadings.members.send = true

        // Remove members
        if (this.members.form.removed && this.members.form.removed.length) {
          await organizationGroupsApi.removeAssocsFromGroup(this.members.form.removed.map(m => {
            return {
              id: m.id,
              role: m.role
            }
          }), this.selectedGroup.cod)
        }

        // Add members
        if (this.members.add.form.selected && this.members.add.form.selected.length) {
          await organizationGroupsApi.addAssocsToGroup(this.members.add.form.selected, this.selectedGroup.cod)
        }

        this.loadings.members.send = false

        this.flags.members = false
      } catch (err) {
        console.error(err)

        this.snackbar.text = `Erro ao salvar membros: ${err}`
        this.flags.snackbar = true
        this.loadings.members.send = false
      }
    },
    openMembersDialog (group) {
      this.selectedGroup = group
      this.flags.members = true
    },
    cleanMembersDialogState () {
      Object.assign(this.members.add, {
        selectedUsers: [],
        selectedRole: null,
        search: [],
        searchText: null,
        nextSearchText: null
      })
      Object.assign(this.members.add.form, {
        selected: []
      })
      Object.assign(this.errors.members, {
        selection: ''
      })
      Object.assign(this.loadings.members, {
        send: false,
        search: false
      })
      this.selectedGroup = null
      this.groupMembers = []
      this.members.next = 0
      this.members.searchText = ''
      this.members.nextSearchText = null
      this.members.lastSearchText = ''
    },
    validateMemberSelection () {
      if (!(this.members.add.selectedUsers.length > 0) || !this.members.add.selectedRole) {
        this.errors.members.selection = 'É necessário selecionar ao menos um usuário e um papel'
        return false
      }
      return true
    },
    addSelectedMembers () {
      if (this.validateMemberSelection()) {
        this.members.add.form.selected.push(...this.members.add.selectedUsers.map(u => {
          return {
            ...u,
            role: this.members.add.selectedRole.value,
            roleName: this.members.add.selectedRole.name
          }
        }))

        this.members.add.selectedUsers = []
        this.members.add.selectedRole = null
      }
    },
    removeAddedMember (assoc) {
      this.members.add.form.selected = this.members.add.form.selected.filter(m => {
        return m.id !== assoc.id || m.role !== assoc.role
      })
    },
    async searchUsers (query) {
      if (!this.loadings.members.add.search && query) {
        this.loadings.members.add.search = true
        this.members.add.nextSearchText = null
        const users = await this.$store.dispatch('users/search', {
          search: query,
          next: 0,
          offset: 15
        })
        this.loadings.members.add.search = false

        if (this.members.add.nextSearchText) {
          this.searchUsers(this.members.add.nextSearchText)
        }

        this.members.add.search = [...users, ...this.members.add.selectedUsers]
      } else if (this.loadings.members.add.search && query) {
        this.members.add.nextSearchText = query
      } else {
        this.members.add.search = this.members.add.selectedUsers
      }
    },

    changePath (value) {
      if (!value.disabled) {
        this.path = value.path
      }
    },
    processPath () {
      const cods = this.path.split('/')

      this.crumbs = []
      let crumbPath = ''
      var i = 0
      for (i in cods) {
        if (cods[i] === 'g') {
          crumbPath += 'g'
          this.crumbs.push({
            text: 'Grupos',
            group: {
              cod: 'g'
            },
            path: 'g',
            disabled: false
          })
        } else {
          const group = this.groups.groups.find(g => g.cod === cods[i])
          crumbPath += `/${group.cod}`
          this.crumbs.push({
            text: group.name,
            group: group,
            path: crumbPath,
            disabled: (parseInt(i) === cods.length - 1)
          })
        }
      }
      this.filterGroups(g => g.parent_cod === cods[i])
    },
    getTopLevelGroups () {
      this.filterGroups(g => g.type === 'filial')
    },
    filterGroups (filter) {
      this.currentGroups = this.groups.groups.filter(filter)
    },
    navigate (group) {
      this.path += `/${group.cod}`
    },
    renameGroup (group) {
    },
    addGroupUser (group) {
    },
    addSubgroup (group) {
    },
    deleteGroup (group) {
    }
  }
}
</script>
