<template lang="pug">
  v-app#login
    v-container.primary(fluid fill-height)
      v-row(no-gutters justify='center' align='center')
        v-col(sm=8 md=7 lg=6)
          v-card()
            v-card-title.title.font-weight-regular
              span {{ currentTitle }}
            v-window(v-model='step' :touchless='true')
              v-window-item(:value='1')
                //- INICIO
                v-card-text.mb-1.text-center
                  v-row(justify='center' align='center')
                    v-img.mb-4(:src='urlImageLogin' alt='Imagem' max-height="200" max-width="200"  contain)
                  v-row(justify='center' align='center')
                    span.body-1.mx-3 Em poucos passos você terá acesso ao aplicativo
              v-window-item(:value='2')
                //- ESCOLHER O TIPO DE USUÁRIO
                v-card-text
                  v-flex(text-center v-show="loading" size=100 key='flex')
                    v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
                  v-flex(v-show="!loading")
                    template(v-for='(profile, i) in profiles')
                      v-list(shaped)
                        v-list-item(two-line @click="selectProfile(profile)")
                          v-list-item-avatar(size='75')
                            v-img(:src="profile.login_method_logo_url" alt="avatar")
                          v-list-item-content
                            v-col
                              v-row.mb-2()
                                span.subtitle-1.font-weight-regular {{ profile.login_method_name }}
                              v-row()
                                span.body-2.font-weight-light {{ profile.login_method_description }}
              v-window-item(:value="3")
                //- USER / MATRICULA / EMAIL / CPF
                v-card-text
                  v-container
                    v-row(justify='center' align='center')
                      v-col(md=10 lg=8)
                        v-form(ref='userLoginForm' v-model='validUserForm' lazy-validation v-on:submit.prevent)
                          v-text-field.pt-3(@keyup.enter='nextStep' v-model='userLogin' :error='showValidUserError' :error-messages="[validUserErrorMessage]" prepend-inner-icon='person' :label='loginMethodType' outlined autofocus)
              v-window-item(:value="4")
                //- SENHA
                v-card-text
                  v-container
                    v-row(justify='center' align='center')
                      v-col(md=10 lg=8)
                        v-form(ref='passwordForm' v-model='validPasswordForm' lazy-validation v-on:submit.prevent)
                          v-text-field.pt-3( :error="passwordError" :error-messages="errorPasswordMessage" v-model='password' :rules="[rules.passwordRequired]" prepend-inner-icon='mdi-lock-question' :counter='counter' :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'visibility' : 'visibility_off'" @click:append="showPassword = !showPassword" label='Sua senha' outlined autofocus v-on:keyup.enter="login")
                  v-btn.text-none(tile elevation=0 text small color="primary" bottom pr="0" @click="goToForgotPassword" depressed :loading='loadingForgotPassword') Esqueceu a senha?
              v-window-item(:value="5")
                //- RECUPERAR SENHA
                v-card-text
                  v-container
                    v-row(justify='center' align='center')
                      span.subtitle-2 Seu email cadastrado se parece com: {{emailTip}}
                    v-row(justify='center' align='center')
                      v-col(md=10 lg=8)
                        v-form(ref='passwordForm' v-model='validPasswordForm' lazy-validation v-on:submit.prevent)
                          v-text-field.pt-3(:error="recoverError" :error-messages="recoverErrorMessage" @keyup.enter='nextStep' v-model='recoverPasswordEmail' :rules="[rules.userRequired]" prepend-inner-icon='person' label='Digite seu email' outlined autofocus v-on:keyup.enter="recover")
                          div(v-if="showNewEmailResult")
                            span.subtitle-1.green--text() Email com link para redefinição de senha foi enviado para {{recoverPasswordEmail}}
              v-window-item(:value="6")
                //- PRIMEIRO ACESSO NOME SOBRENOME
                v-card-text
                  v-form(ref='nameLastNameForm' v-model='validNameLastNameForm' lazy-validation v-on:submit.prevent)
                    v-container
                      v-row(row wrap align='center' justify='center')
                        v-col(md=10 lg=8)
                          v-alert(v-show='errorInvalidLastName' border="right" colored-border type="error" elevation="2")
                            | {{errorInvalidLastName}}
                      v-row(row wrap justify='center' align='center')
                        v-col(md=10 lg=8)
                          span.subtitle-1 Primeiro nome
                          v-text-field(v-model='userFirstName' prepend-inner-icon='mdi-account' :rules="[rules.required]" outlined autofocus)
                      v-row(row wrap justify='center' align='center')
                        v-col(md=10 lg=8)
                          span.subtitle-1 Último nome
                          v-text-field(@keyup.enter='nextStep' v-model='userLastName' prepend-inner-icon='mdi-account' :rules="[rules.required]" outlined)
                      v-scale-transition
                        v-row(v-if="showValidNameError" row wrap justify='center' align='center')
                          v-col(md=10 lg=8)
                            v-alert(outlined text dense type="error") Nomes não conferem!
              v-window-item(:value="7")
                //- PRIMEIRO ACESSO
                v-card-text
                  v-form(ref='firstAccessForm' v-model='validFirstAccessForm' lazy-validation v-on:submit.prevent)
                    v-container
                      v-row(row wrap justify='center' align='center')
                        v-col( md=10 lg=8)
                          span.subtitle-1 Email
                          v-text-field(v-model='emailRegister' prepend-inner-icon='mdi-at' outlined autofocus)
                      v-row(row wrap justify='center' align='center')
                        v-col( md=10 lg=8)
                          span.subtitle-1 Senha
                          v-text-field(v-model='password' :rules="[rules.passwordRequired]" prepend-inner-icon='mdi-lock-question' :counter='counter' :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'visibility' : 'visibility_off'" @click:append="showPassword = !showPassword" outlined)
                      v-row(row wrap justify='center' align='center')
                        v-col( md=10 lg=8)
                          span.subtitle-1 Confirmar senha
                          v-text-field(@keyup.enter='nextStep' prepend-inner-icon='mdi-lock-question' :rules="[rules.passwordRequired, rules.passwordMatch]" :counter='counter' :type="showConfirmPassword ? 'text' : 'password'" :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'" @click:append="showConfirmPassword = !showConfirmPassword" outlined)
              v-window-item(:value="8")
                v-card-text
                  v-container
                    v-row(row wrap justify='center' align='center')
                      v-col(cols=12 md=10 lg=8)
                        span.title {{firstAccessText.title}}
                      v-col(cols=12 md=10 lg=8)
                        span.subheading  {{firstAccessText.subtitle}}
                      v-col(cols=12 md=10 lg=8)
                        span.subheading {{firstAccessText.body}}

            v-card-actions
              v-scale-transition
                v-btn(v-show='step !== 1 && step !== 8' @click="previousStep" text) Voltar
              v-spacer
              v-scale-transition
                v-btn.primary(v-show='step !== 2' @click="nextStep" :loading="loadingStep") {{ (step === 1) ? 'Começar' : 'Continuar' }}
</template>

<script>
import User from '../api/user'
import ApiConfig from '../api/api.config'
import organization from '../api/organization'
import notifications from '../notifications'

export default {
  data () {
    return {
      step: 1,
      validUserForm: true,
      showValidUserError: false,
      showValidNameError: false,
      validUserErrorMessage: 'Preencha o usuário.',
      validPasswordForm: true,
      validNameLastNameForm: true,
      validFirstAccessForm: true,
      selectedProfile: null,
      userLogin: null,
      userEmail: null,
      userFirstName: null,
      userLastName: null,
      showPassword: false,
      showConfirmPassword: false,
      password: '',
      confirmPassword: '',
      counter: 0,
      emailTip: '',
      recoverPasswordEmail: null,
      showNewEmailResult: false,
      didSendEmail: false,
      loading: true,
      loadingStep: false,
      loadingForgotPassword: false,
      passwordError: false,
      errorPasswordMessage: [],
      recoverError: false,
      recoverErrorMessage: [],
      emailRegister: '',
      errorInvalidLastName: false,
      firstAccessText: {
        title: '',
        subtitle: '',
        body: ''
      },
      rules: {
        required: v => !!v || 'Campo obrigatório',
        userRequired: value => !!value || 'Preencha o usuário.',
        passwordRequired: value => !!value || 'Preencha a senha.',
        passwordMatch: value => value === this.password || 'Senhas não conferem.'
      },
      profiles: null,
      urlImageLogin: 'https://coachul.com.br/formacao/images/load.gif'
    }
  },
  methods: {
    async login () {
      this.loadingStep = true
      this.errorPasswordMessage = []
      this.passwordError = false
      const institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      let res = await this.$store.dispatch('auth/login', { institution: institution, user: { user: this.userWithout0, pass: this.password, type: this.selectedProfile.slug } })
      ApiConfig.setup(institution)
      this.loadingStep = false
      this.passwordError = !res
      if (res === true) {
        // Set notifications
        this.$router.push({
          name: 'home'
        })
        notifications.setFCM()
      } else {
        this.errorPasswordMessage.push('Usuário ou senha inválido')
      }
    },
    async recover () {
      this.recoverError = false
      this.recoverErrorMessage = []
      this.loadingStep = true
      if (this.recoverPasswordEmail) {
        const institutionSlug = this.$route.params.institution || process.env.VUE_APP_CORDOVA
        var res = await User.recoverPassword(institutionSlug, this.userWithout0, this.recoverPasswordEmail)
        this.showNewEmailResult = res
        this.loadingStep = false
        if (!res) {
          this.recoverError = true
          this.recoverErrorMessage.push('Email incorreto')
        }
      }
    },
    selectProfile (profile) {
      this.selectedProfile = profile
      this.step++
    },
    async goToForgotPassword () {
      this.loadingForgotPassword = true
      await this.getEmailTip()
      this.loadingForgotPassword = false
      this.step = 5
    },
    async getEmailTip () {
      const institutionSlug = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      var res = await User.getEmailTip(institutionSlug, this.userWithout0)
      this.emailTip = res
    },
    async nextStep () {
      const institutionSlug = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      // if user login name input
      if (this.step === 3) {
        // check user name here
        if (this.userLogin !== '' && typeof this.userLogin !== 'undefined') {
          this.loadingStep = true
          const params = { institutionSlug: institutionSlug, login_method_slug: this.selectedProfile.slug, user: this.userWithout0 }
          let { registered } = await this.$store.dispatch('auth/verifyUser', params)
          if (registered) {
            registered = Boolean(registered && registered === 'true')

            if (registered === false) {
            // continue
            // this.step = 6
              this.step = 8
              this.showFirstAccessAnimation()
            } else if (registered === true) {
              this.step = 4
            } else {
              this.validUserErrorMessage = 'Usuário inválido.'
              this.showValidUserError = true
            }
            this.loadingStep = false
          } else {
            this.loadingStep = false
            this.validUserErrorMessage = 'Usuário não existe'
            this.showValidUserError = true
          }
        } else {
          this.validUserErrorMessage = 'Preencha o usuário.'
          this.showValidUserError = true
        }
      } else if (this.step === 4) {
        if (this.$refs.passwordForm.validate()) {
          this.login()
        }
      } else if (this.step === 5) {
        this.recover()
      } else if (this.step === 6) {
        if (this.$refs.nameLastNameForm.validate()) {
          this.loadingStep = true
          const params = { institutionSlug: institutionSlug, login_method_slug: this.selectedProfile.slug, user: this.userWithout0, firstName: this.userFirstName, lastName: this.userLastName }
          let isValidLastName = await this.$store.dispatch('auth/verifyLastName', params)

          if (isValidLastName.equal === true) {
            if (isValidLastName.email) {
              this.emailRegister = isValidLastName.email
            }
            this.showValidNameError = false
            this.step = 7
          } else if (isValidLastName.equal === false && isValidLastName.error === true) {
            this.errorInvalidLastName = 'Erro inesperado, por favor contato a sua instituição de ensino.'
          } else {
            this.errorInvalidLastName = 'O nome ou sobrenome informado não corresponde ao cadastrado no sistema.'
          }
          this.loadingStep = false
        }
      } else if (this.step === 7) {
        this.loadingStep = true
        const user = await User.registerUser({
          user: this.userWithout0,
          email: this.emailRegister,
          pass: this.password,
          login_method_slug: this.selectedProfile.slug
        })
        if (user) {
          this.login()
        }
        this.loadingStep = false
      } else if (this.step === 8) {
        this.step = 6
      } else {
        this.step++
      }
    },
    previousStep () {
      if (this.step === 4 || this.step === 6) {
        this.step = 3
      } else {
        this.step--
      }
    },
    async typePhrase (el, field, msg) {
      el[field] = ''
      for (const character of msg) {
        el[field] += `${character}`
        await this.waitTime(40)
      }
    },
    async waitTime (time) {
      await new Promise(resolve => setTimeout(resolve, time))
    },
    async showFirstAccessAnimation () {
      await this.typePhrase(this.firstAccessText, 'title', 'Bem vindo!')
      await this.waitTime(500)
      await this.typePhrase(this.firstAccessText, 'subtitle', 'Em poucos passos você terá acesso a plataforma.')
      await this.waitTime(650)
      await this.typePhrase(this.firstAccessText, 'body', 'Realizaremos uma breve verificação a respeito da sua identidade e posteriormente você mesmo irá cadastrar sua senha.')
      await this.waitTime(1400)
      // going to page to verify name
      this.step = 6
    }
  },
  async created () {
    const institutionSlug = this.$route.params.institution || process.env.VUE_APP_CORDOVA
    ApiConfig.setup(institutionSlug)
    const organizationInfo = await organization.infos()
    console.log('ORG', organizationInfo)
    const theme = organizationInfo.theme
    this.$vuetify.theme.themes.light = theme.themes.light
    this.$vuetify.theme.themes.dark = theme.themes.dark
    this.urlImageLogin = organizationInfo.infos.image.login ? organizationInfo.infos.image.login : null

    this.$store.commit('organization/set', organizationInfo)

    var types = await User.getMethodUserTypes(institutionSlug)
    this.loading = false
    this.profiles = types.data
  },
  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Bem vindo(a)!'
        case 2: return 'Escolha seu perfil'
        case 3: return 'Informe seu usuário'
        case 4: return 'Digite sua senha'
        case 5: return 'Preencha os dados abaixo'
        case 6: return 'Preencha seus dados cadastrais'
        default: return ''
      }
    },
    userWithout0 () {
      return this.userLogin
      // var res = ''
      // for (let char of this.userLogin) {
      //   if (char !== '0') {
      //     res = res + char
      //   }
      // }
      // return res
    },
    loginMethodType () {
      if (this.selectedProfile) {
        if (this.selectedProfile.field_type_name) {
          return this.selectedProfile.field_type_name
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped lang="stylus">
  // #login
  //   height: 50%
  //   width: 100%
  //   position: absolute
  //   top: 0
  //   left: 0
  //   content: ""
  //   z-index: 0
</style>
