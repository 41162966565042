<template>
  <v-card flat>
    <v-snackbar
      v-model="snackbar.visible"
      top
      right
    >
      {{ snackbar.text }}
      <v-btn
        color="pink"
        text
        @click="snackbar.visivle = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
    <v-card-title> Gerenciar Templates de Boletins </v-card-title>
    <v-card-text>
      <v-row v-if="loading" justify="center" align="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
      <v-list
        v-if="!loading"
      >
        <v-list-group
          v-for="(template, i) in templates"
          :key="i"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>
              <v-text-field
                v-model="template.name"
                solo
                flat
                hide-details
                background-color="transparent"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-action>
              <v-btn
                icon
                @click="deleteItem(template, templates)"
              >
                <v-icon color="red lighten-1">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </template>

          <v-list-group
            v-for="(etapa, i) in template.etapas"
            :key="i"
            sub-group
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>
                <v-text-field
                  v-model="etapa.name"
                  solo
                  flat
                  hide-details
                  background-color="transparent"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="deleteItem(etapa, template.etapas)"
                >
                  <v-icon color="red lighten-1">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </template>

            <v-list-item
              v-for="(prova, i) in etapa.provas"
              :key="i"
            >
              <v-list-item-title>
                <v-text-field
                  v-model="prova.name"
                  solo
                  flat
                  hide-details
                  background-color="transparent"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="deleteItem(prova, etapa.provas)"
                >
                  <v-icon color="red lighten-1">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              @click="addProva(etapa)"
            >
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Adicionar avaliação
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            @click="addEtapa(template)"
            style="padding-left: 32px"
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Adicionar etapa
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          @click="addTemplate()"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Adicionar modelo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row v-if="!loading">
        <v-spacer/>
        <v-btn @click='save()' color="primary" class="mr-3">
          Salvar
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from '../../src/store/firebase'
import Grades from '../api/grades'
import Catraca from '../api/catraca'
import Post from '../api/posts'
import Notifications from '../api/notification'
import Organization from '../api/organization'
import Presence from '../api/presence'
import User from '../api/user'
const Firestore = firebase.firestore

const Quoti = {
  firestore: Firestore.collection('/dynamicComponents/1mYEpvSJrk1Myzdxkh4A/data/'),
  organizationApi: Organization,
  userApi: User,
  presenceApi: Presence,
  gradesApi: Grades,
  catracaApi: Catraca,
  postApi: Post,
  notificationsApi: Notifications
}

export default {
  data () {
    return {
      snackbar: {
        text: null,
        visible: false
      },
      loading: false,
      templates: [],
      templatesQuerySnapshot: []
    }
  },
  async created () {
    try {
      this.loading = true

      this.templatesQuerySnapshot = await Quoti.firestore.doc('collections').collection('templates').get()

      this.templatesQuerySnapshot.forEach(documentSnapshot => {
        const template = documentSnapshot.data()

        this.templates.push(template)
      })

      this.loading = false
    } catch (err) {
      this.snackbar.text = err.message
      this.snackbar.visible = true

      console.error(err)

      this.loading = false
    }
  },
  methods: {
    deleteItem (item, parent) {
      parent.splice(parent.indexOf(item), 1)
    },
    addProva (item) {
      if (!item.provas) {
        this.$set(item, 'provas', [])
      }

      const name = `Avaliação (${item.provas.length + 1})`
      item.provas.push({
        name
      })
      console.log(this.template)
    },
    addEtapa (item) {
      if (!item.etapas) {
        this.$set(item, 'etapas', [])
      }
      const name = `Etapa (${item.etapas.length + 1})`
      item.etapas.push({ name: name, provas: [] })
    },
    addTemplate () {
      const name = `Modelo (${this.templates.length + 1})`
      const template = {
        'name': name,
        'etapas': []
      }
      this.templates.push(template)
    },
    async save () {
      try {
        this.loading = true

        for (const template of this.templates) {
          await Quoti.firestore.doc('collections').collection(`templates`).doc(template.name).set(template)
        }

        this.snackbar.text = 'Dados salvos com sucesso'
        this.snackbar.visible = true

        this.loading = false
      } catch (err) {
        this.snackbar.text = err.message
        this.snackbar.visible = true

        console.log(err)

        this.loading = false
      }
    }
  }
}
</script>
