<template lang="pug">
  div#feed
    pull-to(@infinite-scroll="loadMore" :bottom-load-method="loadMore" :top-load-method="listPosts" :top-config="topConfig" :bottom-config="bottomConfig" :wrapper-height='scrollStyle')
      template(v-slot:bottom-block)
      v-container#post-list(fluid)
        v-row
          v-col(v-show="posts.list && posts.list.length > 0" xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3')
            Post(
              v-for="item in posts.list"
              :intersectionObserver="intersectionObserver"
              :key="item.id"
              :post='item'
              :refreshWatch='refreshWatch'
              @confirmAction="callConfirmAction"
              @edit="editPost"
              @reply="replyPost"
            )
          v-col.text-center(v-show="!loadings.posts && emptyResponse" cols=12)
            span Nenhuma publicação disponível para você.
          v-col.text-center(v-show="loadings.posts" cols='12' xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3' size=100 key='flex')
            v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
    v-navigation-drawer.text-center(
      v-model="searchDrawer"
      fixed
      temporary
      clipped
      right
    )
      v-toolbar.text-center(dark color="info")
        v-toolbar-title(text-center) Filtros de busca
        v-spacer
        v-btn(icon @click="closeToolbar()")
          v-icon close
      v-flex
        v-list
          v-list-item
            v-list-item-content
              v-checkbox.ma-0.pa-0(color="info" @change='loadPosts' v-model="onlyPending" label="Apenas Postagens pendentes")
          v-list-item(no-gutters)
            v-list-item-content
              //- v-list-item-title(left) Criado por:
              //- TODO: Buscar automaticamente os tipos possíveis de criação de post, ao invés de enumera-los aqui
              v-list-item-title
                AutocompleteSearch(
                  v-model="selectedUsers"
                  :types="['prof_supervised']"
                  :multiple="true"
                  label="Usuário que criou o post"
                )
          v-list-item(v-if="loadings.others")
            v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
          v-list-item(v-else no-gutters v-for="ensino of groupsChildren.ensinos" :key="ensino.id")
            v-radio-group(v-model="filterEnsino" @change='loadPosts')
              v-radio(no-gutters color="info" :value="ensino.cod" :label="`Postagens ${ensino.name}`")
        //- v-checkbox.ma-0.pa-0(color="info" @change='loadPosts' @click='ensinoFundamental=false;ensinoInfantil=false' v-model="ensinoMedio" label="Postagens Ensino Médio")
        //- v-checkbox.ma-0.pa-0(color="info" @change='loadPosts' @click='ensinoMedio=false;ensinoInfantil=false' v-model="ensinoFundamental" label="Postagens Ensino Fundamental")
        //- v-checkbox.ma-0.pa-0(color="info" @change='loadPosts' @click='ensinoMedio=false;ensinoFundamental=false' v-model="ensinoInfantil" label="Postagens Ensino Infantil")
        //- v-checkbox.ma-0.pa-0(color="info" @change='loadPosts' v-model="onlyPending" label="Apenas Postagens pendentes")
        //-br
        //-v-autocomplete(
          filled
          v-model="selectedUsers"
          @change='loadPosts'
          :items="[...usersChildren.admins,...usersChildren.profsSupervised]"
          item-text="name"
          item-value="id"
          clearable
          label="Publicado por: "
          hide-details
          hint="Publicado por: "
          style="max-width: 250px;"
         )
    v-snackbar( v-model="snackbar.enable" :timeout="6000" top right ) {{ snackbar.text }}
      v-btn( color="pink" text @click="snackbar.enable = false" ) Fechar
    v-dialog(
      v-model="flags.notificationCreator"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      Notification(:key="notificationCreator.keyCode" @created="notificationCreated" @close="flags.notificationCreator = false")
    v-dialog(
      v-model="flags.posts.form"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      PostForm(:visible="flags.posts.form" :post="postToEdit" @close="closePostForm" @created="postCreated" @edited="postEdited")
    v-dialog(
      v-model="flags.posts.reply"
      :max-width="$vuetify.breakpoint.smAndDown ? undefined : '800'"
    )
      v-card(:loading="loadings.postReply")
        v-toolbar.elevation-0
          v-card-title Responder postagem
          v-spacer
          v-btn(
            color="grey"
            icon
            large
            dense
            @click="closeReply"
          )
            v-icon close
        v-card-text
          v-text-field(
            ref="postReplyText"
            v-model="postReply.text"
            filled
            rounded
            label="Resposta"
            :rules="[text => text && text.length > 0 || 'A resposta não pode estar vazia']"
            :disabled="loadings.postReply"
          )
            template(v-slot:append)
              v-icon(
                @click="sendPostReply"
              ) send
    v-dialog(v-model='dialogConfirmation.model' persistent max-width='290')
      v-card
        v-card-title.headline
          | {{dialogConfirmation.title}}
        v-card-text
          | {{dialogConfirmation.body}}
        v-card-actions
          .flex-grow-1
          v-btn(color='grey darken-1' text='' @click='dialogConfirmation.model = false; dialogConfirmation.confirmed = false') Cancelar
          v-btn(color='green darken-1' text='' @click='dialogConfirmation.model = false; dialogConfirmation.confirmed = true') Sim
    v-speed-dial(
      v-model="fab"
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
      v-if="validateSomePermission(['posts.create', 'posts.supervisedCreate', 'notifications.create', 'relatorio'])"
    )
      template(v-slot:activator)
        v-btn(v-model="fab" color="primary darken-1" dark fab)
          v-icon(v-if='fab') close
          v-icon(v-else) add
      template(v-if="validatePermissions(['notifications.create'])")
        span.overline(align="center") Notificação
        v-btn(fab dark small color="secondary" @click="flags.notificationCreator = true" :loading="loadings.posts")
          v-icon notifications_active
      template(v-if="validateSomePermission(['posts.create', 'posts.supervisedCreate'])")
        span.overline(align="center") Postagem
        v-btn(fab dark small color="secondary" @click="createPost()" :loading="loadings.posts")
          v-icon post_add
      template(v-if="validatePermissions(['relatorio'])")
        span.overline(align="center") Agenda Diária
        v-btn(fab dark small color="secondary" @click="goToReports")
          v-icon menu_book
</template>

<script>

import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Post from '../components/feed/PostViewer.vue'
import Permissions from '../utils/permissionsManager'
import UploadPhoto from '../components/feed/UploadPhoto.vue'
import Notification from '../components/Notification.vue'
import PostForm from '../components/post/PostForm.vue'
import IntersectionObserver from 'intersection-observer-polyfill'
import PullTo from 'vue-pull-to'
import AutocompleteSearch from '../components/inputs/AutocompleteSearch'

export default {
  name: 'feed',
  components: {
    PullTo,
    Post,
    UploadPhoto,
    Notification,
    PostForm,
    AutocompleteSearch
  },
  data () {
    return {
      intersectionObserver: null,
      dialogConfirmation: {
        model: false,
        confirmed: false,
        title: 'Confirmação',
        body: 'Tem deseja que deseja confirmar essa ação?',
        funcCallback: null
      },
      snackbar: {
        text: '',
        enable: false
      },
      fab: false,
      fabClass: '',
      alreadyPublished: false,
      scheduled: true,
      pending: true,
      notApproved: false,
      postCreatorVisible: false,
      postEditorVisible: false,
      uploadPhotoVisible: false,
      selectedGroups: [],
      // both should be in vuex:
      schoolgroups: [],
      schoolPublishers: [],
      listenerScroll: null,
      refreshWatch: 0,
      notificationCreator: {
        key: 0,
        keyCode: 'notificationCreator-0'
      },
      postForm: {
      },
      flags: {
        notificationCreator: false,
        posts: {
          reply: false,
          form: false
        },
        postForm: false
      },
      loadings: {
        posts: true,
        others: true,
        postReply: false
      },
      postToEdit: null,
      postReply: {
        post: null,
        text: ''
      },
      topConfig: {
        pullText: 'Listando posts', // The text is displayed when you pull down
        triggerText: 'Carregando posts', // The text that appears when the trigger distance is pulled down
        loadingText: 'Carregando postagens...', // The text in the load
        doneText: 'Listagem concluida', // Load the finished text
        failText: 'Erro a listar posts', // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 50, // Trigger the distance after the refresh
        triggerDistance: 70 // Pull down the trigger to trigger the distance
      },
      bottomConfig: {
        pullText: 'Listando postagens',
        triggerText: 'Carregando posts',
        loadingText: 'Carregando postagens...',
        doneText: 'Listagem concluida',
        failText: 'Falha ao listar',
        loadedStayTime: 400,
        stayDistance: 50,
        triggerDistance: 70
      }
    }
  },
  computed: {
    ...mapState(['user', 'search', 'auth', 'posts']),
    ...mapFields(
      'feed', ['searchDrawer', 'onlyPending', 'filterEnsino', 'selectedUsers']
    ),
    ...mapFields('groups', ['groupsChildren']),
    ...mapFields('users', ['users', 'usersChildren']),
    ...mapFields('posts', ['emptyResponse']),
    ...mapState('post', ['postForEdit', 'calledEdit']),
    showingDrawer () {
      return this.$store.getters['app/showingDrawer']
    },
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    scrollStyle () {
      // const toolbarHeight = this.isMobile ? 88 : 96
      console.log('APPPPPPP', this.$vuetify.application)
      const height = `calc(100vh - ${this.$vuetify.application.top + this.$vuetify.application.bottom}px)`
      // const styles = { 'height': height }
      // console.log(styles)
      // return styles
      return height
    }
  },
  watch: {
    calledEdit (data) {
      this.postEditorVisible = true
    },
    showingDrawer (data) {
      this.setFABClass(data)
    },
    'dialogConfirmation.model': async function (status) {
      if (status === false && this.dialogConfirmation.confirmed === true) {
        try {
          await this.dialogConfirmation.funcCallback()
          this.showAction(this.dialogConfirmation.snackbarText)
        } catch (err) {
          console.error(err)
        }
      }
    },
    'search.text': function () {
      this.$store.dispatch('feed/listPosts')
    },
    'flags.notificationCreator': function () {
      this.notificationCreator.key++
      this.notificationCreator.keyCode = `notificationCreator-${this.notificationCreator.key}`
    },
    'flags.posts.reply': function (flag) {
      if (!flag) {
        this.postReply.text = ''
      }
    },
    selectedUsers: function () {
      console.log('Listing posts again')
      this.loadPosts()
    }
  },
  methods: {
    async listPosts (loadedAction) {
      try {
        await this.$store.dispatch('feed/listPosts')
        loadedAction('done')
      } catch (err) {
        loadedAction('fail')
      }
    },
    async onIntersect (entries, observer) {
      if (entries && entries.length > 0 && entries[0].target && entries[0].target.attributes && entries[0].target.attributes['post-id']) {
        const postIdAttribute = entries[0].target.attributes['post-id']
        const postId = postIdAttribute.value
        this.$store.dispatch('posts/read', postId)
        this.intersectionObserver.unobserve(entries[0].target)
      }
    },
    setEnsinoFilter (e) {
      console.log('Set ensino filter', e)
    },
    openPostForm () {
      this.flags.posts.form = true
    },
    closePostForm () {
      this.flags.posts.form = false
    },
    createPost () {
      this.postToEdit = null
      this.openPostForm()
    },
    editPost (post) {
      this.postToEdit = post
      this.openPostForm()
    },
    replyPost (post) {
      this.postReply.post = post
      this.flags.posts.reply = true
    },
    closeReply () {
      this.flags.posts.reply = false
    },
    async sendPostReply () {
      this.loadings.postReply = true

      const post = this.postReply.post

      try {
        if (this.$refs.postReplyText.validate()) {
          const recipient = {
            name: post.mod_user_name ? post.mod_user_name : post.creator_user_name,
            id: post.mod_user_id ? post.mod_user_id : post.creator_user_id
          }
          const sender = {
            id: this.$store.state.user.me.id,
            name: this.$store.state.user.me.name
          }

          // Get chat room to send message
          const room = await this.$store.dispatch('chat/createTitledRoom', [{
            id: recipient.id,
            title: `${post.title} - ${sender.name}`
          }, {
            id: sender.id,
            title: `${post.title} - ${recipient.name}`
          }])

          // Send message to chat room
          await this.$store.dispatch('chat/sendMessage', {
            organization: this.$route.params.institution,
            room,
            text: `${this.postReply.text} (Em beta.minhaescola.app/${this.$route.params.institution}/post/${post.id})`,
            author: sender
          })

          this.closeReply()
        } else {
          throw new Error('a resposta não pode estar vazia')
        }
      } catch (err) {
        console.error(err)
        this.showAction(`Erro ao enviar resposta: ${err}`)
      }

      this.loadings.postReply = false
    },
    setFABClass (data) {
      if (!data) {
        this.fabClass = 'mb-10 pb-5 mr-1'
      } else {
        this.fabClass = ''
      }
    },
    closeToolbar () {
      // this.$store.commit('feed/setSearchDrawer', false)
      this.searchDrawer = false
    },
    async callConfirmAction (options) {
      this.dialogConfirmation.model = true
      this.dialogConfirmation.title = options.title
      this.dialogConfirmation.body = options.body
      this.snackbar.text = options.snackbarText
      this.dialogConfirmation.funcCallback = options.func
    },
    showAction (text) {
      this.snackbar.text = text || this.snackbar.text
      this.snackbar.enable = true
    },
    async loadPosts () {
      this.loadings.posts = true
      let options = {}
      await this.$store.dispatch('feed/listPosts')
      this.loadings.posts = false
      this.$vuetify.goTo(0, options)
      this.forceUpdate()
    },
    async loadMore (loadedAction) {
      try {
        console.log('loading more posts')
        this.loadings.posts = true
        await this.$store.dispatch('feed/listMorePosts')
        this.loadings.posts = false
        // loadedAction('done')
      } catch (err) {
        // loadedAction('fail')
      }
    },
    getMoreData ($stateChange) {
      // this.items.push()
    },
    async refreshData ($pullStateChange) {
      // na vdd tem que aproveitar e simplesmente adicionar os novos resultados no inicio...
      // this.items = await ApiPosts.getStored('list')
    },

    openPostEditor () {
      this.postCreatorVisible = true
    },
    openUploadPhoto () {
      this.uploadPhotoVisible = true
    },
    postEdited () {
      this.closePostForm()
      this.showAction('Postagem editada com sucesso!')
    },
    postCreated () {
      this.closePostForm()
      this.showAction('Postagem criada com sucesso!')
    },
    async handleScroll (e) {
      // const listElm = window
      // if (listElm.scrollY + (4 * listElm.innerHeight) >= document.body.scrollHeight && this.loadings.posts === false) {
      //   this.loadMore()
      // }
    },
    goToReports () {
      this.$router.push({
        name: 'reports'
      })
    },
    forceUpdate () {
      this.refreshWatch++
    },
    notificationCreated () {
      this.showAction('Notificação enviada com sucesso!')
      this.flags.notificationCreator = false
    },
    validatePermissions: Permissions.validatePermissions,
    validateSomePermission: Permissions.validateSomePermission
  },
  destroyed () {
    const listElm = window
    listElm.removeEventListener('scroll', this.handleScroll)
  },
  activated () {
    const listElm = window
    listElm.addEventListener('scroll', this.handleScroll)
  },
  deactivated () {
    const listElm = window
    listElm.addEventListener('scroll', this.handleScroll)
  },
  mounted () {
    const listElm = window
    listElm.addEventListener('scroll', this.handleScroll)
    this.intersectionObserver = new IntersectionObserver(this.onIntersect, {
      root: document.querySelector('#feed'),
      rootMargin: '0px',
      threshold: 0.3
    })
  },
  async created () {
    this.loadings.posts = true
    this.loadings.others = true

    await this.$store.dispatch('feed/listPosts')
    await this.$store.dispatch('user/getMe')
    try {
      await Promise.all([
        this.$store.dispatch('groups/list'),
        // this.$store.dispatch('users/list'),
        this.$store.dispatch('users/listTypes'),
        this.$store.dispatch('groups/listTypes'),
        this.$store.dispatch('posts/listModels'),
        this.$store.dispatch('posts/listCategories')
      ])
    } catch (Err) {
      console.error('ERRO', Err)
    }

    this.loadings.others = false
    this.loadings.posts = false

    this.setFABClass(this.showingDrawer)

    this.forceUpdate()
  }
}
</script>

<style lang="stylus">
  .no-background
    background: none !important
  .action-block.action-block-bottom
    display: none
</style>
