<template lang="pug">
  iframe(:src='url' width="100%" height="100%")
</template>

<script>
import { setTimeout } from 'timers'
export default {
  data () {
    return {
      url: ''
    }
  },
  created () {
    const token = this.$store.getters['auth/token']
    const institution = this.$store.getters['auth/institution']
    localStorage.setItem(`${institution}token`, token)
    localStorage.setItem(`${institution}temp_tokenapp`, token)
    setTimeout(() => {
      this.url = 'https://quoti.com.br/dash/#escolaconecta&temp_token=true'
    }, 500)
  }
}
</script>
